// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ui.grid {
  margin: -1rem !important;
  width: 100vw !important;
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  overflow: hidden !important;
}


.projectContainer {
  display: inline-flex;
  text-align: center;
 
}

.ui.attached.tabular.menu {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;

}

.homeText {
  font-size: 16px;
}


.dividerFont {
  font-family: "Zen Kaku Gothic Antique", sans-serif !important;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 19px !important;
}

.p-p {
  font-size: 17px;
  font-family: "Zen Kaku Gothic Antique", sans-serif !important;
  font-weight: 500 !important;
  font-style: normal !important;
  color: #415e6b;

}

.title {
  font-size: 30px;
}

.mobile {
  display: none;
}







@media (max-width: 480px) {
  .ui.grid {
    margin: 0 !important;
    width: auto !important;
    padding-left: 0rem !important;
    padding-right: 0rem !important;
    overflow: hidden !important;
  }

}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/index.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,uBAAuB;EACvB,6BAA6B;EAC7B,8BAA8B;EAC9B,2BAA2B;AAC7B;;;AAGA;EACE,oBAAoB;EACpB,kBAAkB;;AAEpB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;;AAErB;;AAEA;EACE,eAAe;AACjB;;;AAGA;EACE,6DAA6D;EAC7D,2BAA2B;EAC3B,6BAA6B;EAC7B,0BAA0B;AAC5B;;AAEA;EACE,eAAe;EACf,6DAA6D;EAC7D,2BAA2B;EAC3B,6BAA6B;EAC7B,cAAc;;AAEhB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;;;;;;;AAQA;EACE;IACE,oBAAoB;IACpB,sBAAsB;IACtB,6BAA6B;IAC7B,8BAA8B;IAC9B,2BAA2B;EAC7B;;AAEF","sourcesContent":[".ui.grid {\n  margin: -1rem !important;\n  width: 100vw !important;\n  padding-left: 0rem !important;\n  padding-right: 0rem !important;\n  overflow: hidden !important;\n}\n\n\n.projectContainer {\n  display: inline-flex;\n  text-align: center;\n \n}\n\n.ui.attached.tabular.menu {\n  flex-wrap: wrap;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n}\n\n.homeText {\n  font-size: 16px;\n}\n\n\n.dividerFont {\n  font-family: \"Zen Kaku Gothic Antique\", sans-serif !important;\n  font-weight: 500 !important;\n  font-style: normal !important;\n  font-size: 19px !important;\n}\n\n.p-p {\n  font-size: 17px;\n  font-family: \"Zen Kaku Gothic Antique\", sans-serif !important;\n  font-weight: 500 !important;\n  font-style: normal !important;\n  color: #415e6b;\n\n}\n\n.title {\n  font-size: 30px;\n}\n\n.mobile {\n  display: none;\n}\n\n\n\n\n\n\n\n@media (max-width: 480px) {\n  .ui.grid {\n    margin: 0 !important;\n    width: auto !important;\n    padding-left: 0rem !important;\n    padding-right: 0rem !important;\n    overflow: hidden !important;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
