// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.greeting {
    font-family: "Zen Kaku Gothic Antique", sans-serif;
    font-weight: 500;
    font-style: bold;
    font-size: 30px;
}


.homeText {
    font-size: 15px;
    font-family: "Zen Kaku Gothic Antique", sans-serif;
    font-weight: 500;
    font-style: bold;
    
}

.topMainText {
    font-family: "Zen Kaku Gothic Antique", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 17px;

}

.topic {
    font-weight: bold;
    font-size: 17px;
}

.subTopic {
    font-weight: normal;
    font-size: 15px;

}



@media (max-width: 500px) {
    .greeting {
        line-height: 1.5;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Text/index.css"],"names":[],"mappings":"AAAA;IACI,kDAAkD;IAClD,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;AACnB;;;AAGA;IACI,eAAe;IACf,kDAAkD;IAClD,gBAAgB;IAChB,gBAAgB;;AAEpB;;AAEA;IACI,kDAAkD;IAClD,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;;AAEnB;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,eAAe;;AAEnB;;;;AAIA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".greeting {\n    font-family: \"Zen Kaku Gothic Antique\", sans-serif;\n    font-weight: 500;\n    font-style: bold;\n    font-size: 30px;\n}\n\n\n.homeText {\n    font-size: 15px;\n    font-family: \"Zen Kaku Gothic Antique\", sans-serif;\n    font-weight: 500;\n    font-style: bold;\n    \n}\n\n.topMainText {\n    font-family: \"Zen Kaku Gothic Antique\", sans-serif;\n    font-weight: 500;\n    font-style: normal;\n    font-size: 17px;\n\n}\n\n.topic {\n    font-weight: bold;\n    font-size: 17px;\n}\n\n.subTopic {\n    font-weight: normal;\n    font-size: 15px;\n\n}\n\n\n\n@media (max-width: 500px) {\n    .greeting {\n        line-height: 1.5;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
