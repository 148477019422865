// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ui.container {
    width: 600px !important; 
    margin: 0 auto; 
    padding: 0 20px; 
    text-align: center;
    
  }
  
 
  .ui.attached.tabular.menu {
  margin-left: 0 !important;
  margin-right: 0!important; 
  margin: 0 auto;
  
  }
  
  .title{
    font-family: "Zen Kaku Gothic Antique", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 25px;
  }
  
  .sideNavMenuItems{
    text-align: center !important;
  }
  
  .sideText{
    font-family: "Zen Kaku Gothic Antique", sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 16px;
  }
  .learnMore{
    font-size:14px;
    font-family: "Zen Kaku Gothic Antique", sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 15px;
   
  }


  @media (max-width: 480px){
    .title{
      margin: .5em;
    }

    .learnMore{
      font-weight: 400;
      font-size: 16px;

  }
  .projectContainer{
   margin-top: 1em;
  }

  }`, "",{"version":3,"sources":["webpack://./src/components/SideNav/index.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,cAAc;IACd,eAAe;IACf,kBAAkB;;EAEpB;;;EAGA;EACA,yBAAyB;EACzB,yBAAyB;EACzB,cAAc;;EAEd;;EAEA;IACE,kDAAkD;IAClD,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,6BAA6B;EAC/B;;EAEA;IACE,kDAAkD;IAClD,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;EACjB;EACA;IACE,cAAc;IACd,kDAAkD;IAClD,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;;EAEjB;;;EAGA;IACE;MACE,YAAY;IACd;;IAEA;MACE,gBAAgB;MAChB,eAAe;;EAEnB;EACA;GACC,eAAe;EAChB;;EAEA","sourcesContent":[".ui.container {\n    width: 600px !important; \n    margin: 0 auto; \n    padding: 0 20px; \n    text-align: center;\n    \n  }\n  \n \n  .ui.attached.tabular.menu {\n  margin-left: 0 !important;\n  margin-right: 0!important; \n  margin: 0 auto;\n  \n  }\n  \n  .title{\n    font-family: \"Zen Kaku Gothic Antique\", sans-serif;\n    font-weight: 500;\n    font-style: normal;\n    font-size: 25px;\n  }\n  \n  .sideNavMenuItems{\n    text-align: center !important;\n  }\n  \n  .sideText{\n    font-family: \"Zen Kaku Gothic Antique\", sans-serif;\n    font-weight: 300;\n    font-style: normal;\n    font-size: 16px;\n  }\n  .learnMore{\n    font-size:14px;\n    font-family: \"Zen Kaku Gothic Antique\", sans-serif;\n    font-weight: 300;\n    font-style: normal;\n    font-size: 15px;\n   \n  }\n\n\n  @media (max-width: 480px){\n    .title{\n      margin: .5em;\n    }\n\n    .learnMore{\n      font-weight: 400;\n      font-size: 16px;\n\n  }\n  .projectContainer{\n   margin-top: 1em;\n  }\n\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
