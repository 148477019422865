// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.projectContainer{
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    margin-bottom: 10%;
    
  }
  
  .p-pText{
    font-size: 50px;
    font-family: "Zen Kaku Gothic Antique", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  

  @media (max-width: 480px){
    .bb{
    margin-top: 10% !important;
    margin-bottom: 10%;
   }
  }`, "",{"version":3,"sources":["webpack://./src/components/BouncingBalls/index.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,qBAAqB;IACrB,uBAAuB;IACvB,kBAAkB;IAClB,kBAAkB;;EAEpB;;EAEA;IACE,eAAe;IACf,kDAAkD;IAClD,gBAAgB;IAChB,kBAAkB;EACpB;;;EAGA;IACE;IACA,0BAA0B;IAC1B,kBAAkB;GACnB;EACD","sourcesContent":[".projectContainer{\n    display:flex;\n    flex-direction:column;\n    justify-content: center;\n    align-items:center;\n    margin-bottom: 10%;\n    \n  }\n  \n  .p-pText{\n    font-size: 50px;\n    font-family: \"Zen Kaku Gothic Antique\", sans-serif;\n    font-weight: 500;\n    font-style: normal;\n  }\n  \n\n  @media (max-width: 480px){\n    .bb{\n    margin-top: 10% !important;\n    margin-bottom: 10%;\n   }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
