// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.projectContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10%;

}


.p-pText {
  font-size: 20px;
  font-family: "Zen Kaku Gothic Antique", sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block;
}


.ppLinks {
  font-size: 20px !important;
}



@media (max-width: 480px) {
  .projectImage {
    margin-top: 10% !important;
    margin-bottom: 10% !important;
  }

}`, "",{"version":3,"sources":["webpack://./src/components/CoverArt/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;;AAEpB;;;AAGA;EACE,eAAe;EACf,kDAAkD;EAClD,gBAAgB;EAChB,kBAAkB;EAClB,qBAAqB;AACvB;;;AAGA;EACE,0BAA0B;AAC5B;;;;AAIA;EACE;IACE,0BAA0B;IAC1B,6BAA6B;EAC/B;;AAEF","sourcesContent":[".projectContainer {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin-bottom: 10%;\n\n}\n\n\n.p-pText {\n  font-size: 20px;\n  font-family: \"Zen Kaku Gothic Antique\", sans-serif;\n  font-weight: 500;\n  font-style: normal;\n  display: inline-block;\n}\n\n\n.ppLinks {\n  font-size: 20px !important;\n}\n\n\n\n@media (max-width: 480px) {\n  .projectImage {\n    margin-top: 10% !important;\n    margin-bottom: 10% !important;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
